import {FC, useEffect, useRef, useState} from 'react';
import {Link, Text} from '~/modules';
import {useHover, useHoverDirty} from 'react-use';

import {CloudImage} from '~/modules/Common/components/CloudImage';
import {Collection} from '~/gql/generated';
import {ShoppingBagIcon} from '@heroicons/react/solid';
import {useGetPostImages} from '../hooks/useGetPostImages';

interface PostCardProps {
  post: Collection;
  visibleByDefault: boolean;
  linkPrefix: '/posts' | '/my/content/posts';
  className?: string;
  imageClassName?: string;
  isHoverEnabled?: boolean;
}

export const PostCard: FC<PostCardProps> = ({
  post,
  visibleByDefault,
  linkPrefix,
  className = '',
  imageClassName = '',
  isHoverEnabled = false,
}) => {
  const getPostImages = useGetPostImages(post.id);
  const [images, setImages] = useState<string[]>([]);
  const [maxProducts, setMaxProducts] = useState<number>(0);
  const hoverRef = useRef(null);
  const hasLoadedImages = useRef(false);
  const isHovering = useHoverDirty(hoverRef);

  useEffect(() => {
    if (
      isHoverEnabled &&
      (visibleByDefault || isHovering) &&
      !hasLoadedImages.current
    ) {
      hasLoadedImages.current = true;
      (async () => {
        const {media, max} = await getPostImages();
        setImages(media);
        setMaxProducts(max);
      })();
    }
  }, [isHovering, visibleByDefault, isHoverEnabled, getPostImages]);

  return (
    <Link
      to={`${linkPrefix}/${post.id}`}
      prefetch="none"
      className={`group relative ${className}`}
    >
      <div
        ref={hoverRef}
        className={`aspect-square bg-codGray50 rounded-lg overflow-hidden relative`}
      >
        <CloudImage
          className={`absolute top-0 w-full left-0 fadeIn ${imageClassName}`}
          width={350}
          height={350}
          src={post.mediaUrls?.[0] || ''}
          alt={post.name}
          style={{
            mixBlendMode: 'multiply',
          }}
          visibleByDefault={visibleByDefault}
        />
      </div>
      <div className="absolute bottom-[18px] left-[18px] rounded-full py-1 px-2 center-row gap-1">
        <div className="absolute inset-0 bg-codGray opacity-40 w-full h-full rounded-full" />
        <div className="relative">
          <ShoppingBagIcon fill={'#fff'} height={14} />
        </div>
        <Text size="fine" className="text-white relative">
          {post.productNum || post.productIds?.length || 0}
        </Text>
      </div>
      {images && (
        <div className="absolute rounded-b-lg overflow-hidden bottom-[8px] left-[8px] right-[8px] grid grid-cols-4 realtive">
          {images.map((image, index) => (
            <div
              className="opacity-0 group-hover:opacity-100 duration-300 transition-opacity relative"
              key={image?.src}
            >
              <CloudImage
                width={350}
                height={350}
                src={image?.src}
                alt={image?.alt}
                visibleByDefault={visibleByDefault}
              />
              {index === 3 && maxProducts > 4 && (
                <div className="absolute inset-0 rounded-bl-lg text-base flex flex-col justify-center items-center">
                  <div className="bg-codGray opacity-50 absolute inset-0" />
                  <Text
                    size="copyHeadline"
                    as="span"
                    color="contrast"
                    className="relative"
                  >
                    +{maxProducts - 4}
                  </Text>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </Link>
  );
};
