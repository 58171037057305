import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';

export const useGetPostImages = (id: string) => {
  const action = useExpertLink('/api/post/images');

  const getPostImages = async () => {
    const response = await fetch(action, {
      method: 'post',
      body: JSON.stringify({
        postId: id,
      }),
    });
    const result = await response.json();
    return result;
  };
  return getPostImages;
};
